import { createApp } from 'vue';
import App from './App.vue';
import { registerDirective } from '@/directives/index';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import router from './router';
import less from 'less';
import 'normalize.css';
import '@/assets/css/index.less';
import { getCookie } from '@/utils/getCookie';

// 判断是否移动端
const clientWith = window.screen.width;
const token = getCookie();
if (clientWith <= 768) {
  window.location.replace(
    `https://sciplatform-us-m.steel.org.cn?token=${token}`
  );
}

import { createPinia } from 'pinia';
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
registerDirective(app);

app.use(router).use(less).use(createPinia()).mount('#app');
