import { request } from './http';
import { clientUrl } from './baseURL';
const api = {
  // -------------------------------个人中心
  // 查询政策浏览记录列表
  getPersonBrowsePolicy(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/browsing/history/policy/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  getPersonBrowseNews(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/browsing/history/news/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  getPersonBrowsePatent(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/browsing/history/patent/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  getPersonBrowsePaper(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/browsing/history/paper/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  getPersonBrowseReport(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/browsing/history/report/list`,
      data: data,
      config: config
    };
    return request(params);
  },

  getPersonMessageList() {
    let params = {
      method: 'get',
      url: `${clientUrl}/message/list`,   
    };
    return request(params);
  },
  

  getPersonCollectPolicy(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/collect/policy/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  getPersonCollectNews(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/collect/news/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  getPersonCollectPatent(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/collect/patent/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  getPersonCollectPaper(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/collect/paper/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  getPersonCollectReport(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/collect/report/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  //查看详情
  getPersonMessage(id, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/message/get`,
      data: { id: id },
      config: config
    };
    return request(params);
  },
  getPersonFeedbackList(data, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/feedback/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  getPersonFeedbackClear(data, config) {
    let params = {
      method: 'delete',
      url: `${clientUrl}/feedback/clear`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取验证码
  getCodeImg(config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/code`,
      config: config
    };
    return request(params);
  },
  // 新增反馈
  addFeedback(data, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/feedback/add`,
      data: data,
      config: config
    };
    return request(params);
  },

  //消息列表查询
  getMessageList(data) {
    let params = {
      method: 'get',
      url: `${clientUrl}/message/getMessageList?isRead=${data.isRead}&messageType=${data.messageType}&pageNum=${data.pageNum}&pageSize=${data.pageSize}`,      
    };
    return request(params);
  },

  //消息列表删除
  deleteMessage(ids) {
    let params = {
      method: 'delete',
      url: `${clientUrl}/message/deleteByIds?ids=${ids}`,      
    };
    return request(params);
  },
  //消息列表已读
  readMessage(ids) {
    let params = {
      method: 'get',
      url: `${clientUrl}/message/messageIsRead?ids=${ids}`,      
    };
    return request(params);
  },

  //获取消息顶部统计数据
  getMessageTopCount() {
    let params = {
      method: 'get',
      url: `${clientUrl}/message/getMessageCount`,      
    };
    return request(params);
  },

  //订阅中心获取订阅模块
  getSubscriptionList(businessType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/subscribe/getSubscribeList?businessType=${businessType}`,      
    };
    return request(params);
  },
  //订阅中心添加订阅
  changeSubscription(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/subscribe/addOrCancel?businessType=${data.businessType}&dictCode=${data.dictCode}`,
      data: data
    };
    return request(params);
  },
};
export default api;
